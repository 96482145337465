import request from '@/lib/request'

export function getYktData(data) {
  return request({
    url: 'https://jq.100oa.net/api/jq/checkinfolisttjfx/query/page',
    method: 'get',
    params: data
  })
}
export function getQanYktData(data) {
  return request({
    url: 'https://jq.qhdbwg.cn/api/jq/checkinfolisttjfx/query/page',
    method: 'get',
    params: data
  })
}
export function getAsyncHomeData(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getCompCount',
    method: 'get',
    params: data
  })
}
export function getAreaCount(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getAreaCount',
    method: 'get',
    params: data
  })
}
export function getBaseNum(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getBaseNum',
    method: 'get',
    params: data
  })
}
export function getAreaNum(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getAreaNum',
    method: 'get',
    params: data
  })
}
export function getClassBusi(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getClassBusi',
    method: 'get',
    params: data
  })
}
export function getnodeZb(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getnodeZb',
    method: 'get',
    params: data
  })
}
//食用农产品
export function getSNNodeTypelist(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSNNodeTypelist',
    method: 'get',
    params: data
  })
}
export function getTZhalfyear(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getTZhalfyear',
    method: 'get',
    params: data
  })
}
export function getSNVarietyWeightOrder(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSNVarietyWeightOrder',
    method: 'get',
    params: data
  })
}
export function getSNRetailList(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSNRetailList',
    method: 'get',
    params: data
  })
}
export function getSNRetailWeightList(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSNRetailWeightList',
    method: 'get',
    params: data
  })
}
export function getSNbusiweightList(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSNbusiweightList',
    method: 'get',
    params: data
  })
}
export function getSNareaWeight(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSNareaWeight',
    method: 'get',
    params: data
  })
}
export function getSNNodeWeightTJ(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSNNodeWeightTJ',
    method: 'get',
    params: data
  })
}
export function getSNClassWeight(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSNClassWeight',
    method: 'get',
    params: data
  })
}
// 药品 getYPBase
export function getYPBase(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getYPBase',
    method: 'get',
    params: data
  })
}
export function YpProductSalesTJ(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/YpProductSalesTJ',
    method: 'get',
    params: data
  })
}
export function ypprobase(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/ypprobase',
    method: 'get',
    params: data
  })
}
export function ypretailtran(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/ypretailtran',
    method: 'get',
    params: data
  })
}
//食品

export function getSPvarietyWeightTJ(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSPvarietyWeightTJ',
    method: 'get',
    params: data
  })
}
export function getSPPFweightTJ(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSPPFweightTJ',
    method: 'get',
    params: data
  })
}
export function getSPretailInTJ(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSPretailInTJ',
    method: 'get',
    params: data
  })
}
export function getSPretailInWeiList(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSPretailInWeiList',
    method: 'get',
    params: data
  })
}
export function getSPretailBusiInWeiList(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSPretailBusiInWeiList',
    method: 'get',
    params: data
  })
}
export function getSPInAndOutWeightTJ(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getSPInAndOutWeightTJ',
    method: 'get',
    params: data
  })
}
export function getAreaOrder(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getAreaOrder',
    method: 'get',
    params: data
  })
}
export function getNodecl(data) {
  return request({
    url: 'http://192.168.0.4:8086/api/center/cockpitApi/getNodecl',
    method: 'get',
    params: data
  })
}
